<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />

      <v-card elevation="0" class="d-none d-sm-block">
        <v-card-title>View/Update Map</v-card-title>
        <v-card-actions>
          <v-btn class="orange-bg white--text" @click="showUploadMap">
            Upload New Map
          </v-btn>
          <v-btn v-show="changeMap" @click="cancelUploadMap">
            Cancel
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <div v-show="!mapData || changeMap">
            <MediaUploader
              class="pb-5"
              uploadType="photo"
              :key="mapPictureObj.id"
              :id="`map_${mapPictureObj.id}`"
              :index="mapPictureObj.id"
              :accept="accept"
              :multiple="false"
              :extensions="extensions"
              :sizeImage="10485760"
              :sizeVideo="104857600"
              :fileId.sync="mapPictureObj.fileId"
              :file-url.sync="mapPictureObj.url"
              :isUploading.sync="isFileUploading"
              @onMultipleUploaded="onMultipleAddFile"
              @onChange="onChange"
              @onDeleteFile="onDeleteFile"
            />
          </div>
          <!-- Start Update Map section -->
          <v-row v-show="!changeMap && mapData">
            <v-col sm="2" cols="12">
              <div class="mt-4">
                <img
                  @dragstart="dragstart"
                  width="50px"
                  src="media/assets/my-community-map/icons-fertilize.png"
                  draggable="true"
                />
                <p>Fertilize</p>
              </div>
              <div class="mt-4">
                <img
                  @dragstart="dragstart"
                  width="50px"
                  src="media/assets/my-community-map/icons-irrigation-area.png"
                  draggable="true"
                />
                <p>Irrigation Area</p>
              </div>
              <div class="mt-4">
                <img
                  @dragstart="dragstart"
                  width="50px"
                  src="media/assets/my-community-map/icons-mowing-areas.png"
                  draggable="true"
                />
                <p>Mowing Area</p>
              </div>
              <div class="mt-4">
                <img
                  @dragstart="dragstart"
                  width="50px"
                  src="media/assets/my-community-map/icons-shrub-perfection.png"
                  draggable="true"
                />
                <p>Pruning Area</p>
              </div>
            </v-col>
            <v-col cols="10" @drop="drop" @dragover="dragover">
              <v-stage ref="stage" :config="configKonva">
                <v-layer ref="layer">
                  <!-- <v-image
                    :config="{
                      image: image,
                      scale: { x: 0.7, y: 0.7 }
                    }"
                  /> -->
                </v-layer>
              </v-stage>
            </v-col>
          </v-row>
          <!-- End Recent Orange Reports section -->
        </v-card-text>
      </v-card>
      <!-- </v-container> -->

      <div class="d-none d-sm-block">
        <v-btn @click="saveMapData">
          Save map
        </v-btn>
        <!-- <v-btn @click="deleteMap">
          Delete map
        </v-btn> -->
      </div>
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { GET_QUERY, POST_DATA, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
// import { API_USERS } from "@/core/store/crud.module";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import Konva from "konva";

import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs,
    MediaUploader
  },
  data: () => ({
    configKonva: {
      width: 800,
      height: 600
    },
    image: null,

    communityInfo: {},
    options: {
      page: 1,
      itemsPerPage: 5
    },
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    itemURL: "",
    mapData: null,
    mapPictureObj: { id: 1, url: null },
    attachmentsObj: [{ id: 1, url: null }],
    isFileUploading: false,
    videoExtensions: "mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt",
    changeMap: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    "mapPictureObj.url": async function() {
      this.mapData = {
        mapUrl: this.mapPictureObj.url
      };

      let self = this;
      if (this.mapData.mapUrl)
        this.$nextTick(() => this.setMapImage(self.mapData.mapUrl, self));

      // let image = new window.Image();
      // image.src = self.mapData.mapUrl;
      // image.onload = () => {
      //   // set image only when it is loaded
      //   self.image = image;
      // };
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
  },
  async created() {
    await this.getComunityInfo();
    await this.getMapData();
  },
  methods: {
    setMapImage(mapUrl, self) {
      Konva.Image.fromURL(mapUrl, function(image) {
        var mapNodes = self.$refs.layer.getNode().getChildren(function(node) {
          return node.name() === "map" || node.draggable() === false;
        });

        for (let i = 0; i < mapNodes.length; i++) {
          mapNodes[i].destroy();
        }

        self.$refs.layer.getNode().add(image);

        image.position({ x: 0, y: 0 });
        let stage = self.$refs.stage.getStage();

        let scaleX = stage.width() / image.width();
        let scaleY = stage.height() / image.height();
        image.scale({ x: scaleX, y: scaleY });
        image.draggable(false);
        image.name("map");
        image.zIndex(0);

        self.changeMap = false;
      });
    },

    showUploadMap() {
      this.changeMap = true;
    },
    cancelUploadMap() {
      this.changeMap = false;
    },
    // async deleteMap() {
      
    // },
    async saveMapData() {
      let self = this;
      let mapDataApiUrl = `${API_CUSTOMERS}/${this.customerId}/Map`;

      let nodes = self.$refs.layer.getNode().getChildren(function(node) {
        return node.getAttr("draggable") === true;
      });
      this.mapData.nodes = nodes;
      let mapDataJson = JSON.stringify(this.mapData);

      await this.$store
        .dispatch(POST_DATA, {
          listName: mapDataApiUrl,
          data: { data: mapDataJson }
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            self.$snackbar.showMessage({
              content: "Map has been saved!"
            });
          } else {
            self.$snackbar.showMessage({
              content:
                response.data || "Cannot get map data. Something went wrong!",
              color: "red"
            });
          }
        });
    },

    async getMapData() {
      let self = this;
      let getMapDataApiUrl = `${API_CUSTOMERS}/${this.customerId}/Map`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: getMapDataApiUrl
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            if (response.data) {
              self.mapData = response.data;

              if (response.data.mapUrl) {
                self.setMapImage(response.data.mapUrl, self);
              }

              if (response.data.nodes) {
                for (let i = 0; i < response.data.nodes.length; i++) {
                  let node = JSON.parse(response.data.nodes[i]);
                  let imageURL = node.attrs.name;

                  Konva.Image.fromURL(imageURL, function(image) {
                    self.$refs.layer.getNode().add(image);

                    image.position({
                      x: node.attrs.x,
                      y: node.attrs.y
                    });

                    image.draggable(true);
                    image.name(imageURL);
                    image.zIndex(100);
                  });
                }
              }

              // const image = new window.Image();
              // image.src = self.mapData.mapUrl;
              // image.onload = () => {
              //   // set image only when it is loaded
              //   self.image = image;
              // };
            }
          } else {
            self.$snackbar.showMessage({
              content:
                response.data || "Cannot get map data. Something went wrong!",
              color: "red"
            });
          }
        });
    },
    dragstart(e) {
      this.itemURL = e.target.src;
    },
    dragover(e) {
      e.preventDefault(); // !important
    },
    drop(e) {
      e.preventDefault();
      // now we need to find pointer position
      // we can't use stage.getPointerPosition() here, because that event
      // is not registered by Konva.Stage
      // we can register it manually:
      this.$refs.stage.getStage().setPointersPositions(e);
      let self = this;

      Konva.Image.fromURL(self.itemURL, function(image) {
        self.$refs.layer.getNode().add(image);

        image.position(self.$refs.stage.getStage().getPointerPosition());
        image.draggable(true);
        image.name(self.itemURL);
      });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);

            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "View/Update Map" }
      ];
    },
    setStartingAfter() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.startingAfter = this.userInvoices[this.userInvoices.length - 1].id;
        this.endingBefore = "";
      }
    },
    setEndingBefore() {
      if (this.userInvoices && this.userInvoices.length > 0) {
        this.endingBefore = this.userInvoices[0].id;
        this.startingAfter = "";
      }
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        { title: "View/Update Map" }
      ]);
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      // return moment(date).format("MM/DD/YYYY");
      return dateFormatter.formatDateUs(date);
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        if (found) found.fileId = file.id;
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
      this.mapPictureObj.url = null;
      this.mapPictureObj.fileId = null;
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    }
  }
};
</script>
